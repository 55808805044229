<template>
    <div>
        <div>
            <div class="tbox">
                <el-row :gutter="24" style="margin: 20px 0">
                    <el-col :span="4" class="cengji">一级:</el-col>
                    <el-col :span="20" class="left">
                        <a href="javascript:;" v-for="(option, index) in firstList" :key="option.index"
                            v-bind:class="{ active: (firstIndex === index) }"
                            @click="listBundle(index, option)">{{ option }}
                        </a>
                    </el-col>
                </el-row>
                <el-row :gutter="24" style="margin: 20px 0">
                    <el-col :span="4" class="cengji">二级:</el-col>
                    <el-col :span="20" class="left">
                        <a href="javascript:;"
                            v-for="(option, i) in  secondList"
                            :key="'info2-' + i" v-bind:class="{ active: secondIndex === i }" @click="erlistBundle(i, option)"
                            style="margin-bottom:10px">{{ option }}
                        </a>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="mainbox">
            <span class="zong">共<span style="color: red">{{ total }}</span>个医疗器械</span>
            <dl class="textList">
                <dt v-for="(item, index) in nowData" :key="'info13-' + index">
                    <a @click="itemclick(option.id)" v-for="(option, i) in item.list" :key="'info3-' + i"
                        :title="option.name">
                        <i></i>
                        {{ option.name }}
                    </a>
                </dt>
            </dl>
        </div>
        <el-pagination  @current-change="handleCurrentChange" :page-size="160"
            :current-page="currentPage" layout="prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { queryMedDeviceCatalogOfficial } from 'network/getData1'

export default {
    name: 'MedDevice',
    data() {
        return {
            firstIndex: 0,
            secondIndex: 0,
            total: 0,
            currentPage: 1,
            dempFirst: '内科',
            dempSecond: '心血管内科',
            firstList:[],
            secondList: [],
            nowData: [],
        }
    },
    async mounted() {
        await this.getListData()
    },
    methods: {
        async getListData() {
            try {
                const res = await queryMedDeviceCatalogOfficial()
                this.firstList =  res.data.data.first
                const res1 = await queryMedDeviceCatalogOfficial(undefined, undefined, this.firstList[0], undefined)
                this.secondList =  res1.data.data.second
                this.getDepartmentdata(160, 1,this.firstList[0], this.secondList[0] )
            } catch (error) {
                // console.log(error)
            }
        },
        // 通过请求获取数据
        async getDepartmentdata(rows, page, first, second) {
            try {
                const res = await queryMedDeviceCatalogOfficial(rows, page, first, second)
                const Data = res.data.data.nameList
                this.total = res.data.data.titleNum
                const arr = []
                for (let i = 0; i < Data.length; i++) {
                    const obj = {}
                    if (i === 0) {
                        obj.id = i
                        obj.list = Data.slice(0, 30)
                        arr.push(obj)
                    }
                    if ((i + 1) % 30 === 0) {
                        obj.id = i
                        obj.list = Data.slice(i, i + 30)
                        arr.push(obj)
                    }
                }
                this.nowData = arr
            } catch (error) {
                // console.log(error)
            }
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getDepartmentdata(160, this.currentPage, this.firstList[this.firstIndex], this.secondList[this.secondIndex])
        },
        async listBundle(index, option) {
            const res = await queryMedDeviceCatalogOfficial(undefined, undefined, this.firstList[index], undefined)
            this.secondList =  res.data.data.second
            this.getDepartmentdata(160, 1, this.firstList[index], this.secondList[0])
            this.firstIndex = index
            this.currentPage = 1
            this.secondIndex = 0
        },
        erlistBundle(i, option) {
            this.secondIndex = i
            this.currentPage = 1
            this.getDepartmentdata(160, 1, this.firstList[this.firstIndex], option)
        },
        itemclick(e) {
            this.$router.push({ path: '/medDeviceInfo', query: { id: e } })
        },
        
        
    }
}

</script>

<style scoped>
.tbox {
    /* position: absolute; */
    /* height: 124px; */
    /* margin-left: 120px; */
    /* text-align: center; */
    margin: 0 auto;
    width: 1200px;
    top: 100px;
    background: #FBFBFB;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    border-radius: 0px;
}

.tbox span {
    /* position: absolute; */
    width: 48px;
    height: 24px;
    left: calc(50% - 48px/2 - 542.5px);
    top: 21px;
    /* font-family: Microsoft YaHei; */
    font-size: 15px;
    line-height: 24px;
    /* text-align: center; */
    color: #999999;
}

a {
    display: inline-block;
    margin: 0px 20px 0 0;
    text-decoration: none;
    color: #262626;
}

li {
    list-style: none;
    /* float: left; */
    /* margin: 10px 20px 0 0; */
    width: 90%;
    /* height: 70px; */
    margin-left: 50px;
    margin-top: -3px;
    /* border-bottom: 1px dashed #ccc; */
}

.active {
    padding: 0 5px;
    border-radius: 4px;
    border: 1px solid #F6A609;
    color: #FFFFFF;
    background-color: #F6A609;
}

.mainbox {
    margin-left: 120px;
    width: 1200px;
    margin-top: 20px;
    margin: 0 auto;
}

.zong {
    /* position: absolute; */
    float: right;
    height: 31px;
    /* left: 0%;
    right: 0%;
    top: 0px; */
    border-bottom: 1px solid #eee;
    margin-top: 20px;
}

.textList {
    width: 1200px;
    overflow: hidden;
}

dl {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.textList dt,
.textList dd {
    width: 1200px;
    padding: 18px 0;
}

.textList dt {
    height: 222px;
    border-bottom: 1px dashed #eee;
}

.textList a {
    float: left;
    position: relative;
    height: 40px;
    padding: 8px 0 0 18px;
    line-height: 20px;
    width: 112px;
    margin-right: 18px;
    font-size: 14px;
    color: #8C8C8C;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
}

.textList a i {
    /* position: absolute; */
    top: 18px;
    left: 5px;
    width: 4px;
    height: 2px;
    background: #666;
}

a {
    cursor: pointer;
}

.textList a:hover {
    color: #262626;
}

.cengji {
    text-align: right;
}

.el-pagination {
    /* margin: 0 auto; */
    text-align: center;
    margin-bottom: 50px;
}
</style>